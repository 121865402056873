<template>
  <div>
    <nav id="navbar" class="navbar navbar-dark px-4 col-12 d-xl-none">
      <div class="text-center">
        <div class="position-relative">
          <router-link tag="a" class="nav-link text-white" to="/home">
            <img src="../assets/img/icon.png" alt="logo" width="50" height="50" />
            <span class="position-relative">
              <span class="align-bottom text-white">Hub</span>
            </span>
          </router-link>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <button class="collapsed btn btn-primary btn-nav mt-0" role="button" @click="sidebarOpen = !sidebarOpen">
          <i :class="['fas', sidebarOpen ? 'fa-times' : 'fa-bars']"></i>
        </button>
      </div>
    </nav>
    <transition name="slide">
      <aside v-show="sidebarOpen">
        <sidebar></sidebar>
      </aside>
    </transition>
    <div class="content d-flex flex-column">
      <div class="title mb-2 py-3">
        <div>
          <h3>{{ pageName }}</h3>
        </div>
      </div>
      <div class="row">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mutations } from "../store";
import sidebar from "../components/sidebar";
export default {
  data() {
    return {
      sidebarOpen: true,
      isResponsive: false,
    };
  },
  components: {
    sidebar,
  },
  created() {
    this.getUsers();
    if (mutations.checkUser()) {
      this.getUser();
    }
  },
  mounted() {
    const myObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentRect.width >= 1241) {
          this.sidebarOpen = true;
          this.isResponsive = false;
        } else {
          this.sidebarOpen = false;
          this.isResponsive = true;
        }
      });
    });
    myObserver.observe(document.body);
  },
  computed: {
    pageName() {
      return this.$route.name;
    },
  },
  watch: {
    $route() {
      if (this.isResponsive) {
        this.sidebarOpen = false;
      }
    },
  },
  methods: {
    getUsers() {
      this.$axios({
        url: "/users",
      })
        .then(({ data: { usersList } }) => {
          mutations.setUsers(usersList);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUser() {
      this.$axios
        .get("users/user")
        .then(({ data: { user } }) => {
          mutations.setLoggedInUser(user);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
};
</script>