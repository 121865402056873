<template>
  <div
    id="sidebar"
    class="d-flex flex-column vh-100 flex-shrink-0 p-3 text-white bg-dark position-fixed top-0 bottom-0 start-0 sidebar"
  >
    <div class="text-center">
      <div class="position-relative">
        <img src="../assets/img/icon.png" alt="logo" width="50" height="50" />
        <span class="position-relative">
          <span class="align-bottom">Hub</span>
        </span>
      </div>
    </div>
    <hr />
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item">
        <router-link tag="a" class="nav-link text-white" to="/home">
          <i class="fa fa-home"></i><span class="ms-2">Home</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" class="nav-link text-white" to="/users">
          <i class="fa fa-users"></i><span class="ms-2">Users</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" class="nav-link text-white" to="/phones">
          <i class="fas fa-phone-square-alt"></i
          ><span class="ms-2">Phones</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" class="nav-link text-white" to="/signature">
          <i class="fas fa-file-signature"></i
          ><span class="ms-2">Signature</span>
        </router-link>
      </li>
      <li>
        <a
          href="#"
          class="nav-link text-white"
          data-bs-toggle="collapse"
          data-bs-target="#documents-collapse"
          aria-expanded="false"
        >
          <i class="far fa-file"></i
          ><span class="ms-2"
            >Documents <span class="fas fa-caret-down"></span
          ></span>
        </a>
        <div id="documents-collapse" class="collapse">
          <ul
            class="collapse-list btn-toggle-nav list-unstyled fw-normal pb-1 small"
          >
            <li>
              <router-link
                tag="a"
                class="nav-link text-white"
                to="/password-policy"
              >
                <span>Password Policy</span>
              </router-link>
              <router-link tag="a" class="nav-link text-white" to="/vpn">
                <span>VPN Configuration</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li>
      <li id="adminList">
        <a
          href="#"
          class="nav-link text-white"
          data-bs-toggle="collapse"
          data-bs-target="#admin-collapse"
          aria-expanded="false"
        >
          <i class="fas fa-user-shield"></i
          ><span class="ms-2"
            >{{ loggedInUser.department }}
            <span class="fas fa-caret-down"></span
          ></span>
        </a>
        <div id="admin-collapse" class="collapse admin-collapse">
          <ul
            class="collapse-list btn-toggle-nav list-unstyled fw-normal pb-1 small"
          >
            <li v-hasPermissions.isManager>
              <router-link tag="a" class="nav-link text-white" to="/agenda">
                <i class="fas fa-clipboard-list"></i
                ><span class="ms-2">Agenda</span>
              </router-link>
            </li>
            <li v-hasPermissions="['IT', 'Marketing', 'Dubai']">
              <router-link tag="a" class="nav-link text-white" to="/blog">
                <i class="fas fa-newspaper"></i><span class="ms-2">Blog</span>
              </router-link>
            </li>
            <li v-hasPermissions="['IT']">
              <a
                href="#"
                class="nav-link text-white"
                data-bs-toggle="collapse"
                data-bs-target="#logs-collapse"
                aria-expanded="false"
              >
                <i class="fas fa-file-alt"></i
                ><span class="ms-2"
                  >Logs <span class="fas fa-caret-down"></span
                ></span>
              </a>
              <div id="logs-collapse" class="collapse">
                <ul
                  class="collapse-list btn-toggle-nav list-unstyled fw-normal pb-1 small"
                >
                  <li>
                    <router-link
                      tag="a"
                      class="nav-link text-white sub-list"
                      to="/logins"
                    >
                      <span>Logins</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      tag="a"
                      class="nav-link text-white sub-list"
                      to="/registrations"
                    >
                      <span>Registrations</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li v-hasPermissions="['IT', 'Dealing', 'Compliance']">
              <a
                href="#"
                class="nav-link text-white"
                data-bs-toggle="collapse"
                data-bs-target="#reports-collapse"
                aria-expanded="false"
              >
                <i class="fas fa-file-invoice"></i
                ><span class="ms-2"
                  >Reports <span class="fas fa-caret-down"></span
                ></span>
              </a>
              <div id="reports-collapse" class="collapse">
                <ul
                  class="collapse-list btn-toggle-nav list-unstyled fw-normal pb-1 small"
                >
                  <li>
                    <router-link
                      tag="a"
                      class="nav-link text-white sub-list"
                      to="/reports"
                    >
                      <span>Dormant Accounts</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      tag="a"
                      class="nav-link text-white sub-list"
                      to="/generateReports"
                    >
                      <span>Generate Reports</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      tag="a"
                      class="nav-link text-white sub-list"
                      to="/uploadReports"
                    >
                      <span>Upload Reports</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li v-hasPermissions="['IT', 'Dealing']">
              <router-link tag="a" class="nav-link text-white" to="/swaps">
                <i class="fas fa-exchange-alt"></i
                ><span class="ms-2">Swaps</span>
              </router-link>
            </li>
            <li v-hasPermissions="['IT']">
              <router-link
                tag="a"
                class="nav-link text-white"
                to="/maintenance"
              >
                <i class="fas fa-toggle-on"></i
                ><span class="ms-2">Maintenance</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <hr />
    <div class="dropdown dropup">
      <a
        href="#"
        class="d-flex justify-content-center align-items-center text-white text-decoration-none dropdown-toggle"
        id="profileToggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <strong>{{ loggedInUser.name }}</strong>
      </a>
      <ul
        class="dropdown-menu dropdown-menu-dark text-small shadow"
        aria-labelledby="profileToggle"
      >
        <li>
          <router-link tag="a" class="dropdown-item" to="/profile">
            <span>Profile</span>
          </router-link>
        </li>
        <li>
          <hr class="dropdown-divider" />
        </li>
        <li>
          <a class="dropdown-item" href="#" @click.prevent="logoutUser"
            >Sign out</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { getters, mutations } from "../store";
export default {
  updated() {
    this.hasPermissions();
  },
  computed: {
    loggedInUser() {
      const { name, department } = getters.userLoggedIn();
      return { name, department };
    },
  },
  methods: {
    logoutUser() {
      mutations.logoutUser();
    },
    hasPermissions() {
      const adminList = Array.from(
        document
          .getElementById("adminList")
          .querySelectorAll(".admin-collapse > ul:first-child > li")
      );
      let check = adminList.every((admin) => admin.hidden === true);
      if (check) {
        document.getElementById("adminList").hidden = true;
      }
    },
  },
};
</script>
